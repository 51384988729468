<template>
<opm-page :show-modal="showModal" modal-subtitle="下载App查看贷款详情" @hide="showModal = false">
  <ion-content>
    <ion-item>
      <ion-label>贷款金额</ion-label>
      <ion-input placeholder="$"/>
      <ion-label style="color: #BBBDC4">万</ion-label>
    </ion-item>
    <ion-item>
      <ion-label>贷款期限</ion-label>
      <ion-input placeholder="1~30"/>
      <ion-label style="color: #BBBDC4">年</ion-label>
    </ion-item>
    <ion-item>
      <ion-label>贷款利率</ion-label>
      <ion-input placeholder="0"/>
      <ion-label style="color: #BBBDC4">%</ion-label>
    </ion-item>
    <ion-item style="position: relative" lines="none">
      <ion-label>按揭方式</ion-label>
      <div style="width: 80%;padding: 0 20px">
        <label><input type="radio" name="way" /> 等额本金</label>
        <label style="margin-left: 20px"><input type="radio" name="way" /> 等额本息</label>
      </div>
    </ion-item>
    <div style="width: 80%;margin: 5px auto">
      <ion-button download :href="getDownloadUrl()" style="width: 100%;color: #fff" size="large">下载App查看还款明细</ion-button>
    </div>
  </ion-content>
</opm-page>
</template>

<script>
import OpmPage from "@/components/OpmPage";
import { IonContent, IonList, IonItem, IonLabel, IonAvatar, IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "Rate",
  data () {
    return {
      showModal: false,
      currencies: [
        {currency: 'AUD', name: '澳币 A$'},
        {currency: 'USD', name: '美元 $'},
        {currency: 'GBP', name: '英镑 ￡'},
        {currency: 'NZD', name: '新西兰元NZ $'},
        {currency: 'EUR', name: '欧元 €'},
        {currency: 'CAD', name: '加拿大元 C$'},
        {currency: 'JPY', name: '日元JPY ￥'},
        {currency: 'HKD', name: '港币HKD ￥'},
      ],
      currencyRate: {},
      getDownloadUrl: () => {
        const mode = window && window.Ionic && window.Ionic.mode;
        return mode === 'ios' ? 'https://apps.apple.com/cn/app/%E9%B1%BC%E6%8E%8C%E9%97%A8/id1477809525' : 'https://a.app.qq.com/o/simple.jsp?pkgname=com.adinnet.ironfish&channel=0002160650432d595942&fromcase=60001';
      }
    }
  },
  mounted() {
    document.title = '国际汇率'
    this.$axios.get('/tool/exchange/rate').then(resp => {
      resp.result.forEach((item) => {
        this.currencyRate[item.currency] = (100 / (item.bankConversionPrice / 100)).toFixed(2)
      })
    })
  },
  components: { OpmPage, IonContent, IonItem, IonInput, IonLabel }
})
</script>

<style scoped>

</style>